// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           This is the first step for the <b>easyEv.me</b> project :)
//         </p>
//         <a
//           className="App-link"
//           href="https://easyev.me"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           will be available soon from here
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;


import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

export default function App() {
  return (
    <Router>
      <div>
        <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/users">Users</Link>
            </li>
          </ul>
        </nav>

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/users">
            <Users />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

function Home() {
  return <h2>easyEv.me</h2>;
}

function About() {
  return <h2>This is project to win, regardless of any result</h2>;
}

function Users() {
  return <h2>Jafar and Hani</h2>;
}

